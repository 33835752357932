
.pricing {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;

    max-width: 95%;
    width: 40rem;
    margin: auto;
    // background-color: aquamarine;

    &__title {
      margin: 1rem auto;
      font-family: $font-style-3;
      font-size: 2.8rem;
      color: $secondary-color-1;
    }

    &__text {
      
      &__link {
        color: $secondary-color-1;
        text-decoration: underline;
      }
    }

    &__image {
      background-image: url(../../../public/images/pricing-2.png);
      height: 25rem;
      background-size: contain;
      background-repeat: no-repeat;
      margin-bottom: 2rem;
    }
  }
}