
.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;

  position: absolute;
  // bottom: 0;
  background-color: $primary-color;
  // height: 8rem;
  padding: 2rem 0;
  width: 100%;
  color: $secondary-color-1;

  &__link {
    height: 80px;
  }

  &__logo {
    height: 100%;
  }

  &__copyright {

  }
}