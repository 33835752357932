
.gig-requests {
  padding: 20px;

  h1 {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
  }

  table {
    // width: 100%;
    margin: auto;
    border-collapse: collapse;

    th, td {
      padding: 10px;
      text-align: left;
      border: 1px solid #ccc;
    }

    th {
      background-color: #f2f2f2;
    }

    tr:nth-child(even) {
      background-color: #f5f5f5;
    }
  }
}
