
.image-gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;

  margin: 4rem 0;
  min-height: 100vh;

  &__container {
    position: relative;

    img {
      max-width: 95vw;
      max-height: 20rem; 
      width: auto;
      height: auto;
    }
  }

  &__delete-btn {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    background-color: #ff0000; /* Set your desired background color */
    color: #fff; /* Set text color */
    padding: 0.5rem;
    border: none;
    cursor: pointer;
    font-size: 0.8rem; /* Adjust font size */
    border-radius: 4px;

    &:hover {
      background-color: #cc0000; /* Set a darker color on hover */
    }
  }
}

.image-upload-btn-container {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.image-upload-btn {
  cursor: pointer;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 14px 24px;
}