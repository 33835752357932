.contact-form {
  width: 40rem;
  // margin: auto;
  max-width: 90%;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;

  &__form-group {
    margin-bottom: 1rem;

    label {
      display: block;
      margin-bottom: 0.25rem;
    }

    &__input {
      width: 100%;
      padding: 0.5rem;
      border: 1px solid #ccc;
      border-radius: 4px;
    }
  }

  &__submit-button {
    background-color: $secondary-color-1;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 0.5rem 1rem;
    align-self: flex-end;
    margin: 1rem 0;
    cursor: pointer;

    &:hover {
      background-color: $primary-color;
    }
  }

  &__error-message {
    color: red;
    margin-top: .4rem;
  }
}
