
.about-page {
  min-height: 100vh;

  &__title {
    text-align: center;
    font-family: $font-style-3;
    font-size: 2.8rem;
    color: $secondary-color-1;
    margin: 3rem auto;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &__section {
      display: flex;
      gap: 1rem;

      padding: 1rem 2rem;
      // height: 100%;

      &__item {
      
      }

      &__img {
        width: 60%;
        object-fit: contain;
      }

      &__text {
        padding: 0 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 1;

        @media (max-width: 767px) {
          padding: 0;
        }

        h2 {
          font-family: $font-style-3;
          margin: 1rem auto;
          font-size: 2rem;
          color: $secondary-color-1;
          text-align: center;
        }

        p {
          font-size: 1rem;
        }

        a {
          color: $secondary-color-1;
          text-decoration: underline;
        }
      }
    }
  }
}

@media (max-width: 1000px) {
  .about-page {

    &__content {

      &__section {
        flex-direction: column;
        padding: 1rem 1rem;
        gap: 0;

        &__img {
          width: 100%;
        }

        &__text {
          margin: 4rem auto;
        }
      }
    }
  }
  
  #about-page-section-2 {
    flex-direction: column;  
  }
}

@media (min-width: 1001px) {
  #about-page-section-2 {
    flex-direction: row-reverse;
  }
}