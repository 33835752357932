
.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  transition: opacity 0.3s ease, transform 0.3s ease;
  opacity: 0;
  transform: translateY(-100%);
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease, opacity 0.3s ease;
  transform: translateY(-20px);
  opacity: 0;
  width: 40rem;
  max-width: 85vw;
}

/* Show the modal when 'active' class is added */
.modal-container.active {
  opacity: 1;
  transform: translateY(0);
}

.modal-content.active {
  transform: translateY(0);
  opacity: 1;
}

.delete-btn {
  border: 2px solid red;
  background-color: white;
  color: red;
  border-radius: 8px;
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.delete-btn:hover {
  background-color: red;
  color: white;
}

.close-btn {
  float: right;
  border: none;
  border-radius: 8px;
  padding: 8px 12px;
  background-color: #ddd;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.close-btn:hover {
  background-color: #bbb;
}
