
.video-gallery {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  margin: 4rem 0;
  min-height: 100vh;

  > p {
    margin: auto;
  }

  &__video {
    max-width: 95%;
    width: 40rem;
    margin: auto;
    position: relative;

    video {
      width: 100%;
    }

    h2 {
      margin-top: -6px;
      font-weight: bold;
      font-size: 1.6rem;
      font-family: $font-style-3;
      color: #555;
    }
  }

  &__delete-btn {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    background-color: #ff0000;
    color: #fff;
    padding: 0.5rem;
    border: none;
    cursor: pointer;
    font-size: 0.8rem;
    border-radius: 4px;
    z-index: 10;

    &:hover {
      background-color: #cc0000;
    }
  }
}

.video-upload-btn-container {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.video-upload-btn {
  cursor: pointer;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 14px 24px;
}

.video-uploader-form-group {
  display: flex;
  flex-direction: column;
  gap: 10px;

  justify-content: center;
  margin-bottom: 10px;
}