
.header {
  background-image: url('../../../public/images/header-bg.png');
  background-size: cover;
  // background-position: center;
  background-position: 40% 30%;
  min-height: 100svh;

  @media (max-width: 767px) and (orientation: landscape) {
    min-height: 150svh;
  }

  h2 {
    color: white;
  }
}

#quote {
  color: white;
  // background-color: aquamarine;
  position: absolute;
  top: 40%;
  font-family: $font-style-3;
  font-size: 2rem;
  max-width: 100vw;
  margin: 1rem;
  animation: fadeIn 1s ease-in-out;

  @media (max-width: 767px) and (orientation: landscape) {
    top: 50%;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.8); /* Start with a slightly smaller size */
  }
  to {
    opacity: 1;
    transform: scale(1); /* End with the normal size */
  }
}