
/* Box sizing border-box for all elements */
*, *::before, *::after {
  box-sizing: border-box;
}

/* Remove margin and padding for most elements */
body,
h1, h2, h3, h4, h5, h6,
p,
ul, ol, li,
blockquote,
figure,
pre,
dl, dd, dt {
  margin: 0;
  padding: 0;
}

/* Remove list styles on ul and ol elements */
ul, ol {
  list-style: none;
}

/* Set a consistent baseline for typography */
body {
  font-family: sans-serif;
  line-height: 1.5;
}

/* Remove the default bottom margin for paragraphs */
p {
  margin-bottom: 1rem;
}

/* Remove the default blockquote style */
blockquote {
  margin: 0;
}

/* Reset form elements to their default styles */
button,
input,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0;
}

/* Standardize anchor styling */
a {
  text-decoration: none;
  color: inherit;
}

/* Make images responsive */
img {
  display: block;
  max-width: 100%;
  height: auto;
}

/* Optionally add additional CSS normalization rules as needed */
