.home {
  // background-color: $primary-color;
  min-height: 100vh;

  &__section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    min-height: 100vh;

    p {
      width: 40rem;
      max-width: 95%;
      font-family: $font-style-2;
      font-size: 1.2rem;
    }

    h2 {
      font-family: $font-style-1;
      font-size: 1.6rem;
      color: $secondary-color-1;
    }

    a {
      font-family: $font-style-1;
      font-size: 1.6rem;
      color: $secondary-color-1;
      max-width: 90vw;
    }

    &__1 {

      p {
        max-width: 90vw;
        text-align: center;
      }
    }

    &__2 {
      background-image: url('../../../public/images/section-2-bg.jpg');
      background-size: cover;
      background-position: center;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.5);
        color: white;
      }

      a {
        z-index: 2;
        font-size: 2.6rem;
        margin-bottom: 1em;
      }

      p {
        color: white;
        z-index: 2;
        max-width: 90vw;
      }
    }
    
    &__3 {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      overflow: hidden;
      max-width: 100%; // Use max-width instead of width
      
      @media (max-width: 767px) {
        flex-direction: column;
      }

      > div {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        flex: 1;
        min-height: 100vh;
        // border: solid 1px;
        width: 20%;
        background-color: white;
        margin: 20px 0;

        > div {
          @media (max-width: 767px) {
            margin: 16px;
          }
        }

        @media (max-width: 767px) {
          width: 100%;
          margin: 20px;
          min-height: auto;
        }

        h2 {
          margin: 1rem;
          font-size: 2rem;
        }

        p {
          margin: 1rem;
          color: black;

          @media (max-width: 767px) {
            margin-bottom: 0;
          }
        }
      
        img {
          width: 90%;
          max-width: 100%;
          margin: 1rem auto;
          // border: 10px solid white;
        }

        &:nth-child(even) {
          flex-direction: column-reverse;
          border-right: 1px solid $secondary-color-1;
          border-left: 1px solid $secondary-color-1;

          @media (max-width: 767px) {
            flex-direction: column;
            border: none;
          }
        }
      }
    }
    
    &__4 {
      background-image: url('../../../public/images/section-4-bg.jpg');
      background-size: cover;
      background-position: center;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.5);
        color: white;
      }

      h2 {
        z-index: 2;
        margin-bottom: 3rem;
        font-size: 2.4rem;
      }

      p {
        color: white;
        z-index: 2;
        margin: 6px auto;
        max-width: 90vw;
      }
    }

    &__5 {
      padding: 4rem 0;

      p {
        max-width: 90vw;
      }

      video {
        width: 40rem;
        max-width: 95%;
      }
    }
    
    &__6 {
      h2 {
        font-size: 2rem;
        margin-bottom: 1.6em;
      }
    }
  }
}