
.contact-page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;

  &__title {
    margin: 1rem auto;
    font-family: $font-style-3;
    font-size: 2.8rem;
    color: $secondary-color-1;
  }

  > form {
    margin: auto;
    padding: 1rem 0;
  }
}