.login {
  width: 30rem;
  max-width: 95%;
  margin: auto;
  min-height: 100vh;

  &__form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 4rem;
    border: 1px solid;
    border-radius: 8px;
    padding: 2rem;

    &__input {
      border-radius: 4px;
      border-width: 1px;
      padding: .2rem;
      margin-top: -1rem;
    }

    &__error {
      color: red;
    }
  }

  button {
    cursor: pointer;
    // width: 12rem;
    background-color: white;
    border-color: $secondary-color-1;
    border-radius: 10px;
    border-style: solid;
    font-weight: bold;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    padding: 0.5rem;
    transition: background-color .4s;

    &:hover {
      background-color: $secondary-color-1;
      color: white;
    }
  }
}