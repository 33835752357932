.nav-bar {
  display: flex;
  justify-content: space-between;
  align-items: center; 

  padding: 0 2rem;
  background-color: $primary-color;
  color: $secondary-color-1;
  height: 4rem;

  &__link {
    padding: 2px 0;
    display: flex;
    text-decoration: none;
    height: 100%;
    gap: .6rem;
  }

  &__logo {
    height: 100%; 
    // max-height: 3.6rem;
    // margin-right: 1rem; 
  }

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__name {
      font-family: $font-style-3;
      font-size: 2.2rem;
      font-weight: bold;
      font-style: italic;
      color: $secondary-color-1;
      line-height: 1;
    }

    &__subtext {
      font-size: .85rem;
    }

    p {
      margin: 0;
    }
  }

  // p {
  //   margin: 0;
  //   // margin-bottom: -.4rem;
  //   font-family: $font-style-3;
  //   font-size: 2rem;
  //   font-weight: bold;
  //   color: $secondary-color-1;
  //   display: flex;
  //   align-items: center;
  // }
}
