.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 20px;
    
    background: #fff;
    max-width: 90%;
    // min-height: 200px;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    text-align: center;

    p {
      margin-bottom: 20px;
    }

    button {
      background: #3498db;
      color: #fff;
      padding: 10px 20px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      margin-right: 10px;

      &:last-child {
        background: #e74c3c;
      }
    }
  }
}
