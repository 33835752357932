
.thank-you-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h2 {
      font-size: 2rem;
      color: $secondary-color-1;
      text-align: center;
      margin: auto 2rem;
    }
  }
}