
.nav-card-btn {
  cursor: pointer;
  border: none;
  background-color: transparent;
  color: $secondary-color-1;
  font-size: 2rem;
  padding: 0;
  margin: 0;
}

.nav-card {
  position: fixed;
  // right: 4rem;
  top: 2rem;
  max-width: 90vw;
  width: 21rem;
  height: 30rem;
  border-radius: 18px;
  backface-visibility: hidden;
  perspective: 100rem;
  z-index: 1000;
  transition: all 1s;

  // background-color: black;
  // border: 1px black solid;

  &__front {
    position: absolute;
    height: 100%;
    width: 100%;
    background-image: url('../../../public/images/card-front-min.png');
    background-size: cover;
    transition: all 1s;

    &__list {
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      position: absolute;
      margin: 0;
      height: 100%;
      width: 100%;
      padding: 0;
      background-color: rgba(0, 0, 0, 0.6);
      list-style: none;
      // color: white;
      border-radius: 18px;
      overflow: hidden;

      li {
        height: 4rem;
        overflow: hidden;
        display: flex;
        align-items: center;
      }

      &__item {
        cursor: pointer;
        display: flex;
        align-items: center;
        border: none;
        background-color: transparent;
        // background-color: rgba(255, 255, 255, 0.2);
        color: $secondary-color-1;
        // margin-left: 1rem;
        margin: 0;
        padding: 0;
        padding-left: 1rem;
        font-weight: bold;
        font-family: 'Lato', sans-serif;
        font-size: 1.4rem;
        text-decoration: none;
        width: 100%;
        height: 100%;
        transition: background-color .4s;

        &:hover {
          background-color: black;
        }

        &__text {
          margin-left: 18px;
        }
      }
    }

    &__not-active {
      transform: rotateY(360deg);
    }

    &__active {
      transform: rotateY(0deg);
    }
  }

  &__back {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    background-image: url(../../../public/images/card-back-min.png);
    background-size: cover;
    background-position: center;
    transition: all 1s;
    backface-visibility: inherit;

    &__not-active {
      transform: rotateY(180deg);
    }

    &__active {
      transform: rotateY(-180deg);
    }
  }

  &__not-active {
    right: -23rem;
  }

  &__active {
    right: 1rem;
  }
}